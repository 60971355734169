<div mat-dialog-header>
  <h1>Compare Products</h1>
</div>
<mat-dialog-content #scroll class="dialog-container">
  <div class="row compare-container col-12">
    <ng-container *ngFor="let t of tables">
      <kendo-grid
        #grid
        [kendoGridBinding]="t.gridData"
        [sortable]="t.type === 'horz'"
        class="compare-table w-100"
        (dataStateChange)="onDataStateChange($event)"
        [resizable]="true"
        [style.maxHeight.pct]="90"
      >
        <kendo-grid-column
          field="metric"
          title=""
          width="105"
          [locked]="true"
          *ngIf="t.type !== 'horz'"
        ></kendo-grid-column>
        <ng-container *ngIf="t.type !== 'horz'">
          <kendo-grid-column
            *ngFor="let column of t.columns"
            field="{{ column.id }}"
            title="{{ column.name }}"
            [width]="300"
          ></kendo-grid-column>
        </ng-container>
        <ng-container *ngIf="t.type === 'horz'">
          <kendo-grid-column
            *ngFor="let column of t.columns"
            field="{{ column.id }}"
            title="{{ column.name }}"
            [width]="column.id === 'name' ? 250 : 150"
            [locked]="column.id === 'name'"
          ></kendo-grid-column>
        </ng-container>
      </kendo-grid>
      <ng-container *ngIf="t.footer && t.footer.footnotes">
        <p class="small" *ngFor="let z of t.footer.footnotes">{{ z }}</p>
      </ng-container>
    </ng-container>
  </div>
</mat-dialog-content>
<mat-dialog-actions class="row buttons">
  <button mat-stroked-button color="primary" (click)="downloadComparisonPdf()">
    <mat-icon>download</mat-icon>Download Comparison
  </button>
  <button mat-raised-button (click)="dialogRef.close('Cancel')">Close</button>
</mat-dialog-actions>
<ngx-loading
  [show]="loading"
  [config]="{
    fullScreenBackdrop: true,
    backdropBackgroundColour: 'rgba(0,0,0,.5)'
  }"
></ngx-loading>
