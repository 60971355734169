import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import {
  SortDescriptor,
  State,
  GroupDescriptor,
  CompositeFilterDescriptor,
} from '@progress/kendo-data-query';
import { FormGroup, FormBuilder, FormControl } from '@angular/forms';
import { FilterService, PageChangeEvent } from '@progress/kendo-angular-grid';
import { CaseManagementService } from '../case-management.service';
import { CustomFiltersService } from '../../services/custom-filters.service';
import { SessionStorageService } from '../../services/session-storage.service';
import { MatDialog } from '@angular/material/dialog';
import { AdHocModalComponent } from '../ad-hoc-modal/ad-hoc-modal.component';
import { Subject } from 'rxjs';
import { takeUntil, debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { RightBridgeApiService } from '../../services/right-bridge-api.service';
import { AdminService } from '../../admin/admin.service';
import { WarnDialogComponent } from '../../warn-dialog/warn-dialog.component';
import { getFilterMenuText } from '../../lib/grid-sort-text';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-case-management',
  templateUrl: './case-management.component.html',
  styleUrls: ['./case-management.component.scss'],
})
export class CaseManagementComponent implements OnInit, OnDestroy {
  @ViewChild('caseManagementGrid', { static: false }) caseManagementGrid;

  constructor(
    private cms: CaseManagementService,
    private cstmFltrSrvc: CustomFiltersService,
    private ss: SessionStorageService,
    private activatedRoute: ActivatedRoute,
    public fb: FormBuilder,
    private dialog: MatDialog,
    private rbs: RightBridgeApiService,
    private adminService: AdminService,
    private route: Router,
    private snacky: MatSnackBar
  ) {}

  globals = this.ss.get('globals');

  assignedToControl = new FormControl();
  autocompleteFilters = ['AssignedTo', 'SubmittedBy'];
  canDeleteCase = false;
  currentFilters: CompositeFilterDescriptor = { logic: 'and', filters: [] };
  dateFilters = {};
  defaultUser = this.globals ? this.globals.user : null;
  dropdownFilters = {};
  dropdownOptions = {};
  externalFilters = [];
  ogFilterData = {};
  filterData = {};
  filterForm: FormGroup = this.fb.group({});
  formFields = { primaryForm: [], secondaryForm: [] };
  hideGrid = true;
  loading = false;
  ogExternalFilters = [];
  public filter: CompositeFilterDescriptor = { logic: 'and', filters: [] };
  public gridColumns = [];
  public gridData;
  public group: GroupDescriptor[] = [];
  public hidden: string[] = [];
  public ogHidden: string[] = [];
  public ogData;
  public pageSize = 25;
  public pageSizeOptions = [25, 50, 75, 100];
  public skip = 0;
  public sort: SortDescriptor[] = [{ field: 'DateLastUpdated', dir: 'desc' }];
  public getFilterMenuText = getFilterMenuText;
  resetFilters = false;
  rights: string[];
  submitteByControl = new FormControl();
  titleControl = new FormControl();
  typeControl = new FormControl();
  unsubscribe: Subject<any> = new Subject();
  filterOptions = {
    Type: [],
    AssignedToName: [],
    SubmittedBy: [],
    Status: [],
  };
  state: State = {
    filter: this.filter,
    sort: this.sort,
    skip: this.skip,
    take: this.pageSize,
  };

  ngOnInit(): void {
    this.rights = this.ss.get('rights');
    this.canDeleteCase = this.rights.includes('DeleteCRID');
    this.getGridSetup();
  }

  getGridSetup() {
    this.filterOptions = {
      Type: [],
      AssignedToName: [],
      SubmittedBy: [],
      Status: [],
    };

    this.adminService
      .getUIConfigHeaders({
        uiconfig: 'cm-grid',
        type: 'cm-grid',
        implementation: 'cm',
      })
      .subscribe(res => {
        this.gridColumns = res['headers'];
        this.dropdownOptions = res['dropdowns'];

        if (this.gridColumns && this.gridColumns.length > 0) {
          this.setColumnVisiblity();

          this.activatedRoute.params
            .pipe(takeUntil(this.unsubscribe))
            .subscribe(params => {
              if (params?.id !== 'undefined') {
                this.getGridData(+params.id);
              } else {
                this.getGridData();
              }
            });
        } else {
          console.error('No grid column data returned from request.');
        }
      });
  }

  ngOnDestroy() {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  getUserList(searchString?: string) {
    this.cms.getCmUserFilterList(searchString).subscribe(resp => {
      const newUsersList = resp.Users.map(user => ({
        value: user.UserID,
        display: user.UserName,
      }));
      this.autocompleteFilters.map(filter => {
        this.filterOptions[filter] = resp.Users;
        this.gridColumns.find(column => {
          return column.DataField === filter;
        }).externalFilter.options = newUsersList;
      });
      this.cms.setModalPreparing(false);
    });
  }

  gridHeight() {
    return window.innerHeight - 100;
  }

  getGridData(defaultCRID?) {
    this.loading = true;
    const data = {
      search: { profileValues: {} },
      skip: this.state.skip,
      take: this.state.take,
      sort: {},
    };
    data.sort = {
      DateLastUpdated: 'DESC',
    };

    if (defaultCRID) {
      data.search.profileValues['CRID'] = defaultCRID;
    }

    if (this.titleControl && this.titleControl.value) {
      data.search.profileValues['Title'] = this.titleControl.value;
    }

    if (this.typeControl && this.typeControl.value) {
      data.search.profileValues['Type'] = this.typeControl.value;
    }

    if (this.filter.filters && this.filter.filters.length > 0) {
      data.search = { profileValues: data.search.profileValues };
      this.filter.filters.forEach(filter => {
        if (
          filter['operator'] !== 'start' &&
          filter['operator'] !== 'end' &&
          !filter['customRange']
        ) {
          data.search.profileValues[filter['field']] = filter['value'];
        } else if (!data.search[filter['field']]) {
          data.search[filter['field']] = {};
        }
        if (filter['operator'] === 'start') {
          data.search[filter['field']]['start'] = filter['value'];
        } else if (filter['operator'] === 'end') {
          data.search[filter['field']]['end'] = filter['value'];
        }
        if (filter['customRange']) {
          data.search[filter['field']]['start'] =
            filter['customRange']['start'];
          data.search[filter['field']]['end'] = filter['customRange']['end'];
        }
      });
    }

    if (this.sort && this.sort.length > 0) {
      this.sort.forEach(sort => {
        if (sort['dir']) {
          data.sort[sort['field']] = sort['dir'].toUpperCase();
        } else {
          delete data.sort[sort['field']];
        }
      });
    }
    this.cms.getCaseManagementData(data).subscribe(resp => {
      const gridData = { data: resp['Data'], total: resp['total'] };
      this.state.skip = resp['skip'];
      this.ogData = gridData;
      this.gridData = gridData;
      this.buildFilterOptions();
      this.updateExternalFilterOptions(this.gridColumns);

      if (defaultCRID) {
        this.filterValueChange([defaultCRID], 'CRID', new FilterService());
        this.reviewCase({ CRID: defaultCRID });
      }

      this.buildDropdownOptions(this.dropdownOptions);

      this.hideGrid = false;
      this.loading = false;
    });
  }

  buildDropdownOptions(data) {
    Object.keys(data).forEach(key => {
      data[key].forEach(option => {
        const optionToAdd = { value: option, display: option };
        if (!Object.keys(this.filterOptions).includes(key)) {
          this.filterOptions[key] = [];
        }
        if (
          this.filterOptions[key].filter(
            filterOption => filterOption.value === optionToAdd.value
          ).length === 0
        ) {
          this.filterOptions[key].push(optionToAdd);
        }
      });
      const typeFilter = this.externalFilters.find(
        x => x.fullField.DataField === key
      );
      typeFilter.DataField = typeFilter.fullField.DataField;
      typeFilter.options = this.filterOptions[key];
    });
  }

  buildFilterOptions() {
    this.filterData = this.cstmFltrSrvc.buildColumnFilterOptions(
      this.ogData,
      this.gridColumns
    );
    this.ogFilterData = this.cstmFltrSrvc.buildColumnFilterOptions(
      this.ogData,
      this.gridColumns
    );
  }

  public filterValueChange(
    values: any[],
    field,
    filterService: FilterService
  ): void {
    filterService.filter({
      filters: values.map(value => ({
        field: field,
        operator: 'eq',
        value,
      })),
      logic: 'or',
    });
  }

  public onFilterChange(searchTerm: string, dataSet): void {
    if (!searchTerm) {
      this.filterData[dataSet] = this.ogFilterData[dataSet];
    } else {
      this.filterData[dataSet] = this.filterData[dataSet].filter(dataPoint =>
        dataPoint.value.toLowerCase().includes(searchTerm.toLowerCase())
      );
    }
  }

  externalColumnsUpdated(ev) {
    // This is a hack to stop the grid from scrolling to the right
    const gridContentScroll = document.querySelector('.k-grid-content');
    const oldScrollPosition = gridContentScroll.scrollLeft;

    const headerWrapContentScroll = document.querySelector(
      '.k-grid-header-wrap'
    );
    const headerWrapPosition = headerWrapContentScroll.scrollLeft;
    setTimeout(() => {
      gridContentScroll.scrollLeft = oldScrollPosition;
      headerWrapContentScroll.scrollLeft = headerWrapPosition;
    });

    const fullList = this.gridColumns.map(x => {
      return x.DataField;
    });
    const hidden = fullList.filter(y => !ev.includes(y));

    this.updateHidden({ value: hidden });
  }

  useDefault() {
    this.filter = { logic: 'and', filters: [] };
    this.dateFilters = {};
    this.dropdownFilters = {};
    this.assignedToControl.setValue('');
    this.submitteByControl.setValue('');
    this.sort = [];
    this.state = {
      skip: this.skip,
      take: this.pageSize,
      group: this.group,
      sort: this.sort,
      filter: this.filter,
    };

    this.getGridData();
  }

  setColumnVisiblity() {
    this.hidden = [];
    this.gridColumns.forEach(x => {
      if (x.Hidden) {
        this.hidden.push(x.DataField);
        this.ogHidden.push(x.DataField);
      }
    });
  }

  updateHidden(ev) {
    this.hidden = [...ev.value];
  }

  isHidden(columnName: string): boolean {
    return this.hidden.indexOf(columnName) > -1;
  }

  updateFilters(ev) {
    this.resetFilters = false;
    this.skip = 0;

    this.filter = ev;

    if (this.filter.filters && this.filter.filters.length > 0) {
      this.filter.filters = this.deDupObjectArray([...this.filter.filters]);
    }
  }

  searchResults() {
    this.state = {
      filter: this.filter,
      sort: this.sort,
      skip: this.skip,
      take: this.pageSize,
    };
    this.getGridData();
  }

  deDupObjectArray(data) {
    const uniqueArray = data.filter(
      (object, index) =>
        index ===
        data.findIndex(obj => JSON.stringify(obj) === JSON.stringify(object))
    );
    return uniqueArray;
  }

  updateExternalFilterOptions(headers) {
    headers
      .filter(x => x.externalFilter)
      .forEach(el => {
        const entry = el.externalFilter;
        entry.varName = el.DataField;
        entry.fullField = el;

        if (
          this.externalFilters.findIndex(x => x.varName === el.DataField) === -1
        ) {
          this.externalFilters.push(entry);
        }

        if (
          el.Type &&
          el.Type.toLowerCase() == 'curr' &&
          el.externalFilter &&
          el.externalFilter.controlType !== 'currencyRange'
        ) {
          entry.controlType = 'currency';
        }

        const entryIdx = this.externalFilters.findIndex(x => {
          return x.varName == el.DataField;
        });
        this.externalFilters[entryIdx].options =
          this.buildDropdownFilterOptions(el);
      });
  }

  buildDropdownFilterOptions(el) {
    let options = [];
    if (this.filterOptions[el.DataField]) {
      options = this.filterOptions[el.DataField].map(x => {
        if (typeof x === 'object') {
          return { value: x.UserID, display: x.UserName };
        } else {
          return { value: x, display: x };
        }
      });
    }
    options = this.deDupObjectArray(options);
    options = this.clearEmptyFields(options);
    options = options.sort((a, b) => (a.display > b.display ? 1 : -1));

    if (el.Validation) {
      options = el.Validation.map(x => {
        const label = x.label ? x.label : x.value;

        return { value: x.value, display: label };
      });
    } else if (!el.Validation && el.externalFilter.options) {
      options = el.externalFilter.options.map(x => {
        const label = x.display ? x.display : x.value;

        return { value: x.value, display: label };
      });
    }

    return options;
  }

  showClearButton(fieldName) {
    if (
      this.state.filter.filters.find(filter => filter['field'] === fieldName)
    ) {
      return true;
    }
    return false;
  }

  getDropdownOptions(fieldName) {
    return this.externalFilters.find(filter => filter.label === fieldName)
      ? this.externalFilters.find(filter => filter.label === fieldName).options
      : [];
  }

  getFilteredOptions() {
    this.assignedToControl.valueChanges
      .pipe(debounceTime(500), distinctUntilChanged())
      .subscribe(value => {
        this.getUserList(value);
      });

    this.submitteByControl.valueChanges
      .pipe(debounceTime(500), distinctUntilChanged())
      .subscribe(value => {
        this.getUserList(value);
      });
  }

  clearEmptyFields(data) {
    const updated = data.filter(item => {
      if (typeof item.value === 'string' && item.value.length < 1) {
        return false;
      }

      return item;
    });

    return updated;
  }

  sortChange(sort: SortDescriptor[]) {
    this.sort = sort;
    this.getGridData();
  }

  public pageChange(event: PageChangeEvent): void {
    this.skip = event.skip;
    this.state.skip = this.skip;
    this.state.take = event.take;
    this.getGridData();
  }

  reset() {
    this.loading = true;
    this.hideGrid = true;
    this.sort = [];
    this.filter = { logic: 'and', filters: [] };
    this.skip = 0;
    this.buildFilterOptions();
    this.externalFilters = [];

    this.state = {
      filter: this.filter,
      sort: this.sort,
      skip: this.skip,
      take: this.pageSize,
    };

    this.resetFilters = true;

    this.getGridSetup();
  }

  reviewCase(caseData) {
    this.nav(`case-management/case-manager/${caseData.CRID}`);
  }

  createAdHocCase() {
    const dialogRef = this.dialog.open(AdHocModalComponent, {
      panelClass: 'app-ad-hoc-modal',
      data: {
        typeFilters: this.externalFilters.filter(
          filter => filter.varName === 'Type'
        )[0].options,
      },
      width: '50vw',
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result && result.CRID) {
        this.route.navigate([`/case-management/case-manager/${result.CRID}`]);
      }
    });
  }

  deleteCase(dataItem) {
    let dialogRef = this.dialog.open(WarnDialogComponent, {
      panelClass: 'warn-dialog',
      data: {
        headline: 'Attention!',
        content: `Are you sure you want to delete this case? This cannot be undone.`,
        confirm: 'Confirm',
        hideCancel: false,
      },
    });
    dialogRef.afterClosed().subscribe(result => {
      dialogRef = null;
      if (result === 'continue') {
        this.loading = true;
        this.cms.deleteCase(dataItem.CRID.toString()).subscribe(
          res => {
            if (res && res['status'] === 'Success') {
              this.getGridSetup();
              this.snacky.open(
                `${dataItem.CRID.toString()} was deleted.`,
                'Close',
                {
                  duration: 4000,
                }
              );
            } else {
              this.snacky.open(
                `There was an error. ${dataItem.CRID.toString()} could not be updated.`,
                'Close',
                {
                  duration: 4000,
                }
              );
            }
          },
          error => console.error(error)
        );
      }
    });
  }

  nav(location) {
    this.route.navigate([`/${location}`]);
  }
}
